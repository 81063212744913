
.login {
  width: max-content;
  height: max-content;
  color: #415a77;
  border: 2px solid #415a77;
  border-radius: 24px;
  overflow: hidden;
}
.user-picture {
  width: 42px;
  height: 42px;
  color: #415a77;
  border: 2px solid #415a77;
  border-radius: 30px;
  overflow: hidden;
  cursor: pointer;
}
.about-us-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 100px;
  transform: translate(0%, -50%);
}
.faq-image {
  margin-top: 20px;
  width: 100%;
  height: 500px;
}
.min-h-default {
  min-height: calc(100vh - 410px);
}
.rounded-div-none div {
  border-radius: 0 !important;
}
.focus\:ring-4:focus {
  box-shadow: none !important;
}